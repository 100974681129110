import React from 'react'
import { AviaryIIIFPlayer } from 'aviary-iiif-player';
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const navigate = useNavigate();
    const [manifestUrl, setManifestUrl] = React.useState(null)
    let query = window.location.href.split('?manifest=');
    let url = "";
    if(query.length > 0)
        url = query[1];
    React.useEffect(() => {
        if (!url) {
            navigate(`../`, { replace: true });
        }
        setManifestUrl(url)
       
    }, [url])

    return (
        <>
            {(manifestUrl) ? (
                <div>
                    <div>
                        <AviaryIIIFPlayer manifest={manifestUrl} />
                    </div>
                </div>
            )
                : ""
            }

        </>

    )
}

export default Main
